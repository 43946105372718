<!-- 模块设备 -->
<template>
  <div>
    <div style="height: 80px; width: 100%; background-color: #fff"></div>
    <div class="solution-page">
      <img src="../../assets/images/solution/cp-ban (1).png" />
      <div class="solution-content">
        <div class="module-img" v-if="visiable">
          <div class="backstyle" @click="goHome()">返回主页</div>

            <h2>模块设备</h2>
            <em></em>
            <div class="font"></div>
            <div class="case-box">
              <ul v-for="(item, index) in imgList" :key="index">
                <li>
                  <div class="modular" @click="getId(item.id)">
                    <div class="img-box">
                      <img
                        :src="item.imgUrl"
                        :title="item.name"
                        :alt="item.name"
                        class="img-slace"
                        :style="{ height: item.height, width: item.width }"
                      />
                      <div class="case-text">
                        <h4>{{ item.name }}</h4>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
          </div>
        </div>
        <div v-if="!visiable">
          <div class="backstyle" @click="goHome2()">返回主页</div>
          <div v-if="id == '1'" class="synopsis">
            <h2>预处理整体模块</h2>
            <img
              src="@/assets/images/home/图片40.webp"
              style="height: 400px; width: 700px"
            />
          </div>
          <div v-if="id == '2'" class="synopsis">
            <h2>挤压模块</h2>
            <img
              src="@/assets/images/home/图片41.webp"
              style="height: 400px; width: 700px"
            />
          </div>
          <div v-if="id == '3'" class="synopsis">
            <h2>脱水模块</h2>
            <img
              src="@/assets/images/home/图片42.webp"
              style="height: 480px; width: 600px"
            />
          </div>
          <div v-if="id == '4'" class="synopsis">
            <h2>破碎模块</h2>
            <img
              src="@/assets/images/home/图片43.webp"
              style="height: 600px; width: 700px"
            />
          </div>
      </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      visiable: true,
      id: "",
      imgList: [
        {
          id: 1,
          name: "预处理整体模块",
          imgUrl: require("@/assets/images/home/图片40.webp"),
        },
        {
          id: 2,
          name: "挤压模块",
          imgUrl: require("@/assets/images/home/图片41.webp"),
        },
        {
          id: 3,
          name: "脱水模块",
          imgUrl: require("@/assets/images/home/图片42.webp"),
          height: "200px",
          width: "240px",
        },
        {
          id: 4,
          imgUrl: require("@/assets/images/home/图片43.webp"),
          name: "破碎模块",
          height: "200px",
          width: "240px",
        },

      ],
    };
  },
  mounted() {
    this.id = this.$route.query.id
  },
  created() {
      this.id = this.$route.query.id;
        if (this.id == undefined) {
          console.log(this.id);
      this.visiable = true;
    } else {

      console.log(this.id);
        this.visiable = false
          this.$router.push({
          path: "./modularEquipment",
          query: {
            id: this.id,
          },
      })
    }

},

  methods: {
    goHome() {
      this.id = "";
      this.visiable = true;
      this.$router.go(-1);
    },
    goHome2() {
      this.id = this.$route.query.id;
      console.log(this.id);
      if(this.id==undefined){
        this.id = "";
      this.visiable = true;
      }else{
        // this.$router.push({
        //   path: "./index",
        // })
        this.$router.go(-1);
      }


    },
    getId(e) {
      this.visiable = false;
      console.log(e);
      this.id = e
    },
  },
};
</script>
  <style scoped>
.synopsis {
  text-align: center;
  width: 100%;
  margin: 30px;
}
.synopsis h2 {
  font-size: 31px;
  color: #3b3b3b;
  padding: 5px 0;
}
.backstyle {
  font-size: 20px;
  margin-left: 20px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  width: 100px;
  display: block;
  margin: 20px;
  background: #8cc6ee;
  border: 1px solid white;
  color: #fff;
}
.img-slace {
  transition: all 0.5s ease 0.1s;
}
.img-slace:hover {
  transform: scale(1.05);
}
.solution-page img {
  display: block;
  margin: auto;
}
.module-img h2 {
  text-align: center;
  font-weight: 400;
  font-size: 31px;
  margin-top: 20px;
}
.fonts {
  padding-top: 60px;
  margin-top: 40px;
}
.case-box {
  width: 100%;
  padding-top: 20px;
  overflow: hidden;
}
.case-box ul li {
  width: 23%;
  margin: 10px 1%;
  float: left;
  overflow: hidden;
}
.img-box img {
  height: 200px;
  width: 300px;
  background: #8cc6ee;
  display: flex;
  flex-direction: row;
}
.img-box1 img {
  height: 200px;
  width: 230px;
  background: #8cc6ee;
}
.case-text {
  width: 100%;
  background: #8cc6ee;
}

.case-text h4 {
  color: #fff;
  width: 100%;
  margin: auto;
  padding: 10px 0;
  text-align: center;
  font-size: 16px;
}

.solution-imgtext {
  width: 100%;
  overflow: hidden;
  float: left;
  padding: 30px 0;
  background: #fff;
}
.solution-imgtext img {
  width: 33%;
  float: left;
}
.solution-text {
  width: 64%;
  background: #fff;
  float: left;
  overflow: hidden;
  margin-left: 20px;
}
.solution-text h3 {
  font-weight: 1000;
  font-size: 25px;
  color: cornflowerblue;
  text-align: left;
}
.text-detail {
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
  line-height: 35px;
  font-size: 18px;
  text-indent: 2em;
  text-align: left;
}
.solution-text {
  padding: 30px;
}
.solution-main {
  text-align: center;
  width: 100%;
}
.solution-main h2 {
  font-size: 35px;
}
.solution-main em {
  display: block;
  width: 60px;
  height: 5px;
  margin: auto;
  margin-top: 10px;
  background: #8cc6ee;
}
.solution-main h2 {
  color: #3b3b3b;
  margin-top: 65px;
}
.left-nav {
  padding: 20px 0;
  height: 40px;
  line-height: 40px;
  margin: auto;
}
.font {
  padding: 10px 0 10px 20px;
  border-bottom: 1px solid #eee;
}
.left-nav ul li {
  display: block;
  float: left;
  font-size: 20px;
  margin-left: 12px;
  text-align: center;
}
.left-nav ul li a {
  display: block;
  padding: 0 20px;
  background: #0e6eb8;
  border: 1px solid white;
  color: #fff;
}
.router-link-active {
  text-decoration: none;
  color: #fff;
  margin-top: 3px;
}
.solution-content {
  width: 1200px;
  margin: auto;
}
.solution-page {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
  background: #fff;
}
</style>
